  body {
    position: relative;
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
    font-family: "MuseoSansRegular";
  }
  strong {
    font-family: "MuseoSansBold";
  }

  th strong {
    color: rgba(0, 0, 0, 0.7);
  }
  td strong {
    color: rgba(0, 0, 0, 0.7);
  }

  h1, h2, h3 {
    color: rgba(0, 0, 0, 0.7);
    margin-top: .5rem;
    font-family: "MuseoSansLight";
    font-size: 2rem;
    /* line-height: 1.167rem; */
    letter-spacing: -0.04em;
    font-weight: normal;
    margin-bottom: 1.5rem;
  }
  h2 {
    font-size: 1.75rem;
    margin-bottom: 1.25rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  h4 {
    color: rgba(0, 0, 0, 0.7);
    margin-top: .5rem;
    font-family: "MuseoSansRegular";
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    /* line-height: 1.5rem; */
  }

  h5 {
    color: rgba(0, 0, 0, 0.6);
    margin-top: .25rem;
    font-family: "MuseoSansBold";
    font-size: 1rem;
    /* line-height: 1.25rem; */
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  h6 {
    color: rgba(0, 0, 0, 0.6);
    font-family: "MuseoSansBold";
    /* margin-bottom: 0.25rem; */
    font-size: 0.85rem;
    /* line-height: 1.25rem; */
    margin-top: .25rem;
    margin-bottom: 0.25rem;
  }

  a {
    transition: all 0.2s ease-in-out;
    display: inline-block;
    /* vertical-align: middle; */
    color: #645DD7;
  }
  p {
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0;
  }
  p+p{
    margin-top: 0.5rem;
  }
  .disabled {
    opacity: 0.3;
  }

  .lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 0.5rem;
    width: 1rem;
    background: #645DD7;
    animation: lds-facebook 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 0.5rem;
    animation-delay: -0.2s;
  }
  .lds-facebook div:nth-child(2) {
    left: 2rem;
    animation-delay: -0.1s;
  }
  .lds-facebook div:nth-child(3) {
    left: 3.5rem;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%,
    100% {
      top: 24px;
      height: 32px;
    }
  }

  .grow {
    flex: 1 1 auto;
  }

  .hightlight {
    background-color: rgb(255, 251, 0);
    color: black;
}
