.frame-wine {
    display: grid;
    grid-auto-flow: row;
    gap: 0.5rem;
    grid-gap: 0.5rem;
    margin-top: 1.5rem;    
}

.wine-catalog-title {
    font-family: "MuseoSansLight";
    color: #bf8040;
    position: relative;
}

.wine-catalog-title .level-1 {
    font-size: 2rem;
    letter-spacing: -.04em;
    line-height: 2.5rem;
    width: max-content;
    padding: 0 0.5rem;
    margin: 0 0 0.25rem 1rem;
    background-color: #fff;
}

.wine-catalog-title .level-1::before {
    display: block;
    background-color: #BF8040;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 1.375rem;
    left: 0;
    right: 0;
    content: '';
    z-index: -1;
  }

.wine-catalog-title .level-2 {
    font-size: 1.5rem;
    letter-spacing: -.04em;
    line-height: 1.75rem;
    margin: 0.25rem 0 0.25rem 1.5rem;
}

.wine-catalog-title .level-3 {
    font-size: 1.25rem;
    font-family: "MuseoSansRegular";
    line-height: 1.75rem;
    margin: 0.25rem 0 0.25rem 1.5rem;
}

.wine-card {
    display: grid;
    position: relative;
    transition: all .2s ease-in-out;
    background: #fff;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    margin-left: 2rem;
    padding: .5rem 0 0;
    /* border-radius: 0.25rem; */
    /* display: grid; */

    /* box-shadow: 0 0 0.125rem rgb(0 0 0 / 8%), 0 0.125rem 0.25rem rgb(0 0 0 / 16%); */
}    

.wine-card .card-content {
    display: grid;
    /* grid-template-columns: auto 5rem min-content; */
    gap: 0 1rem;
    grid-gap: 0 1rem;
    align-items: center;

    grid-template-columns: auto min-content 5rem 2rem min-content;
    padding: 0;
}

.wine-card .card-content .label-box{
    margin-top: 0;
    white-space: nowrap;
}

@media (max-width: 760px) {
    .wine-card .card-content .label-box{
        white-space: unset;
    }
}
.wine-card .card-content .title{
    cursor: pointer;
    font-size: 1.25rem;
}
.wine-card .card-content .label-box .label-group{
    margin-top: 0;
}
.wine-card .card-content .label{
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.125rem;
    white-space: nowrap;
    margin-bottom: 0;
}

.card-content .count-zero {
    opacity: 0.5;
  }
  .card-content .count-zero .title {
    text-decoration: line-through;
}

.cm-qty-view {
    height: 2rem;
    cursor: pointer;
    white-space: nowrap;
    grid-column-start: 5;
    grid-row-start: 1;
    align-self: center;
    text-align: right;
  }
  .cm-qty-view span {
    font-weight: 600;
    line-height: 2rem;
  }
  .cm-qty-view > span:first-of-type {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.5);
  }
  .cm-qty-view > span:first-of-type::after {
    display: inline;
    font-weight: 100;
    content: '/';
    color: rgba(0, 0, 0, 0.3);
    padding: 0 0.125rem;
  }
  .cm-qty-view > span:last-of-type {
    font-size: 1.5rem;
    color: #645DD7;
  }

  .cm-qty-edit {
    position: relative;
    grid-column-start: 5;
    align-self: center;
    margin-top: 0;
    grid-row-start: 1;
    white-space: nowrap;
    text-align: right;

  }
  .cm-qty-edit div {
    position: absolute;
    padding: .5rem;
    right: 0;
    top: -1.5625rem;
    background-color: #ffffff;
    border-radius: 999rem;
    border: 1px solid #645DD7;
    box-shadow: 0 0.5rem 1.5rem rgba(61, 52, 205, 0.32);
  }
  .cm-qty-edit button + span {
    display: inline-block;
    text-align: center;
    padding: 0 0.5rem;
    min-width: 3rem;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    vertical-align: middle;
  }  
  .wine-card .card-content .grid {
    margin: 0;
    margin-top: 1rem;
  }
  .wine-card .card-content .grid dd {
    color: rgb(0,0,0, 0.5);
  }

@media (max-width: 48rem) {
    .wine-card .card-content {
        grid-template-columns: auto 5rem min-content;
        /* padding-bottom: .5rem */
        /* padding: 0.5rem 1rem; */
    }
    .wine-card .card-content .title {
        grid-column-start: span 2;
    }
    .wine-card .card-content .cm-qty-view {
        grid-column-start: 3;
        grid-row-start: 1;
        white-space: nowrap;
        text-align: right;
        position: relative;
        align-self: flex-start;
        margin-top: 0.3125rem;
    }
    .wine-card .card-content .grid {
        grid-column-start: span 3;
        display: grid;
        grid-template-columns: auto;
        grid-gap: 0;
        /* margin: 1rem; */
        /* margin-bottom: 0.5rem; */
    }
    .cm-qty-edit {
        grid-column-start: 3;
        grid-row-start: 1;
        white-space: nowrap;
        text-align: right;
        position: relative;
        align-self: flex-start;
        margin-top: 0.3125rem;
    }
}