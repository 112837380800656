
.filter-frame {
    display: grid;
    grid-auto-flow: row;
    gap: 0.125rem;
    grid-gap: 0.125rem;
    position: sticky;
    top: 80px;
    overflow: auto;
    max-height: calc(100vh - 90px);
}

.filter-search-box {
    background-color: rgba(0,0,0,.04);
    padding: 0.5rem 1rem;
}

.filter-list {
    background-color: rgba(0,0,0,.04);
}

.filter-list .disabled {
    opacity: .3;
}

.filter-switch-box {
    background-color: rgba(0,0,0,.04);
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
}
.filter-catalog-item {
    font-size: 0.875rem;
    color: var(--color-action);
    position: absolute;
    right: 3rem;    
}


