/* font-family: "Museo Sans Light"; */
@font-face {
    font-family: "MuseoSansLight";
    src: local("MuseoSansLight"), url("../fonts/museo/MuseoSansLight.woff") format("woff");
    font-style: normal;
    font-weight: normal;
 
    /* font-family: "MuseoSansLight";
    src: url("../fonts/museo/MuseoSansLight.eot");
    src: url("../fonts/museo/MuseoSansLight.eot?#iefix")format("embedded-opentype"),
    url("../fonts/museo/MuseoSansLight.woff") format("woff"),
    url("../fonts/museo/MuseoSansLight.ttf") format("truetype");
    font-style: normal;
    font-weight: normal; */
}
/* font-family: "Museo Sans Bold"; */
@font-face {
    font-family: "MuseoSansBold";
    src: local("MuseoSansBold"), url("../fonts/museo/MuseoSansBold.woff") format("woff");
    font-style: normal;
    font-weight: normal;

    /* font-family: "MuseoSansBold";
    src: url("../fonts/museo/MuseoSansBold.eot");
    src: url("../fonts/museo/MuseoSansBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/museo/MuseoSansBold.woff") format("woff"),
    url("../fonts/museo/MuseoSansBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal; */
}

/* font-family: "Museo Sans Regular"; */
@font-face {
    font-family: "MuseoSansRegular";
    src: local("MuseoSansRegular"), url("../fonts/museo/MuseoSansRegular.woff") format("woff");
    font-style: normal;
    font-weight: normal;

    /* font-family: "MuseoSansRegular";
    src: url("../fonts/museo/MuseoSansRegular.eot");
    src: url("../fonts/museo/MuseoSansRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/museo/MuseoSansRegular.woff") format("woff"),
    url("../fonts/museo/MuseoSansRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal; */
}
/* font-family: "Museo Sans Medium"; */
/* @font-face {
    font-family: "MuseoSansMedium";
    src: url("../fonts/museo/MuseoSansMedium.eot");
    src: url("../fonts/museo/MuseoSansMedium.eot?#iefix")format("embedded-opentype"),
    url("../fonts/museo/MuseoSansMedium.woff") format("woff"),
    url("../fonts/museo/MuseoSansMedium.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
} */


/* font-family: "Museo Slab Bold"; */
/* @font-face {
    font-family: "MuseoSlabBold";
    src: url("../fonts/museo/MuseoCyrl-700.eot");
    src: url("../fonts/museo/MuseoCyrl-700.eot?#iefix")format("embedded-opentype"),
    url("../fonts/museo/MuseoCyrl-700.woff") format("woff"),
    url("../fonts/museo/MuseoCyrl-700.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
} */