:root {
    --color-primary: #3c69e1;
    --color-secondary: #E57C04;
    --color-divider: rgba(0,0,0,0.12);
    --color-background: #fff;
    --color-catalog: #bf8040;
    --color-action: rgba(0, 0, 0, 0.54);
}
  
.card-lines  {
    display: grid;
    grid-template-columns: min-content auto min-content;
    position: relative;
    transition: all .2s ease-in-out;
    border-bottom: 1px solid;
    /* border-radius: 8px; */
    border-color: var(--color-divider);
    gap: 0;
    grid-gap: 0   
}

.card-cards  {
    display: grid;
    grid-template-rows: min-content auto min-content;
    position: relative;
    transition: all .2s ease-in-out;
    border-bottom: 1px solid;
    /* border-radius: 8px; */
    border-color: var(--color-divider);
    gap: 0;
    grid-gap: 0;
    margin: 0 8px;
}

.card-scale  {
    display: grid;
    grid-template-columns: min-content auto;
    grid-auto-rows: min-content;
    position: relative;
    transition: all .2s ease-in-out;
    border-bottom: 1px solid;
    /* border-radius: 8px; */
    border-color: var(--color-divider);
    gap: 0;
    grid-gap: 0;
    padding-bottom: 1.25rem;
}

.card-body {
    padding: 1rem;
    padding-bottom: 0;
    position: relative;
}

.card-cards .card-body {
    /* padding: 0.5rem .5rem 1.5rem; */
    padding: 0.5rem 0 1.5rem;

    /* margin */
}

.frame-lines {
    margin-top: 1.5rem;
    grid-auto-flow: row;
    gap: 1rem;
    grid-gap: 1rem;
    display: grid;
    box-sizing: border-box;
    /* gridTemplateColumns: '', */
}
.frame-cards {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(15rem,1fr));
    gap: 2rem;
    grid-gap: 2rem;
}
.frame-scale {
    margin-top: 1.5rem;
    grid-auto-flow: row;
    gap: 1rem;
    grid-gap: 1rem;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: '';
}

.card-cards .image-box {
    /* width: calc(100% - 1rem); */
    display: flex;
    /* margin: 0.5rem 0.5rem 0; */
    margin: 0.5rem 0 0;
    align-self: start;
    float: left;     
}

.card-lines .image-box {
    display: flex;
    margin: 0.5rem 0;
    align-self: start;
    float: left;   
    padding-bottom: 1.25rem;  
}
.card-lines .extra-small {
    width: 4rem;
    /* height: 2rem; */
}
.card-lines .small {
    width: 16rem;
    /* height: 8rem; */
}
.card-lines .medium {
    width: 32rem;
    /* height: 16rem; */
}
.card-lines .large {
    width: 64rem;
    /* height: 32rem; */
}

.card-scale .image-box {
    display: flex;
    margin: 0.5rem 0;
    align-self: start;
    float: left;    
    grid-row-start: span 2;
}
.card-scale .extra-small {
    width: 4rem;
    /* height: 2rem; */
}
.card-scale .small {
    width: 16rem;
    /* height: 8rem; */
}
.card-scale .medium {
    width: 32rem;
    /* height: 16rem; */
}
.card-scale .large {
    width: 64rem;
    /* height: 32rem; */
}

.subitems-box {
    padding-top: 0.5rem;
    /* border-top: 1px solid; */
    /* border-color: var(--color-divider); */
    grid-column-start: span 2;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: .5rem;
    gap: .5rem;
    margin: 0 0.5rem .5rem;
}

.card-lines .subitems-box {
    grid-column-start: span 3
}
.subitem {
    display: grid;
    grid-template-columns: min-content auto;
}

.image-border {
    width: 100%;
    height: 100%;
    /* border-right: 1px solid #fff; */
    cursor: pointer;
}
.image-border-right {
    width: 50%;
    border-right: 1px solid #fff;
    cursor: pointer;
}
.image-border-left {
    width: 50%;
    border-left: 1px solid #fff;
    cursor: pointer;
}

.chip {
    display: inline-block;
    font-size: .75rem;
    line-height: 1.125rem;
    color: rgba(0,0,0,.5);
    padding: 0.125rem 0.5rem;
} 

.chip-border {
    border-Left: 1px solid rgba(0,0,0,.1)
} 


.chips {
    position: absolute;
    bottom: -1px;
    /* left: 0.5rem; */
    left: 0;
    border: 1px solid;
    border-color: var(--color-divider);
    /* border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    box-shadow: 0 -0.125rem 0.25rem rgb(0 0 0 / 8%); */
    background-color: var(--color-background)
}
.item-view .chips {
    position: absolute;
    top: 0;
    right: 0;
    left: unset;
    bottom: unset;
    border-radius: 0.25rem;
    border: 1px dashed rgba(0,0,0,.1);
    font-size: .875rem;
    font-weight: 600;
    box-shadow: unset;
    /* border-top-left-radius: unset;
    border-top-right-radius: unset; */

    color: rgba(0,0,0,.5)
}

.flag {
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 600;
    letter-spacing: .04em;
    padding: 0.25rem 0.5rem;
    color: hsla(0,0%,100%,.9);
    border: 1px solid rgba(0,0,0,.1);
    white-space: nowrap;
    background-color: rgba(38,169,108,.85);
}

.flag-to-find {
    background-color: rgba(246,174,45,.85);
}
.flag-dropped {
    background-color: rgba(255,58,32,.85);
}

.flags {
    display: grid;
    top: 1rem;
    grid-gap: 0.125rem;
    position: absolute;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    overflow: hidden;
    width: max-content;
}

.catalog {
    display: block;
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: var(--color-catalog);
    width: 90%;
}

.title {
    display: block;
    color: rgba(0,0,0,.8);
    font-size: 1.4rem;
    line-height: 1.875rem;
    text-decoration: none;
    /* margin-top: 0.5rem; */
}

.subitem .title {
    margin-top: 0.125rem;
    line-height: 1.25rem;
    font-size: 1rem;
}

.subitem .number {
    display: inline-block;
    margin-top: .5rem;
    font-family: "MuseoSansBold";
    font-size: .75rem;
    line-height: 1.125rem;
    color: rgba(0,0,0,.5);
}

.subtitle {
    display: block;
    color: rgba(0,0,0,.6);
    font-size: .875rem;
    margin-top: 0.125rem;
}
.subitem .subtitle {
    margin-top: 0.125rem;
}

.label-box {
    margin-top: 0.5rem;
    font-size: .875rem;
    line-height: 1.125rem;
    color: rgba(0,0,0,.8);
}
.label {
    display: inline-block;
    margin-right: 0.5rem;
}
.label-group {
    margin-top: .5rem;
}
.framed {
    border-radius: 0.25rem;
    background-color: rgba(191,128,64,.1);
    padding: 0.25rem 0.375rem;
    border: 1px solid rgba(0,0,0,.1);
    margin-top: 0.25rem    
}

.grid {
    margin: 0.875rem 0 0.5rem;
}

.grid dl {
    display: inline-block;
    margin: 0;
    padding: 0.125rem 0 0.125rem 0.5rem;
    padding-right: 2rem;
    border-left: 1px solid rgba(0,0,0,.1)
}
.grid dt {
    color: rgba(0,0,0,.5);
    font-size: .75rem;
    line-height: 1rem;
}
.grid dd {
    color: rgba(0,0,0,.8);
    font-size: .875rem;
    line-height: 1.125rem;
    margin: 0
}
.card-cards .grid dl {
    display: block;
}

.url-box {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    margin-top: 0.5rem;
}
.url {
    display: flex;
    align-items: center;
    gap: 2px;
    width: 100%;
    position: relative;
}
.shared-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
}

.card-cards .source-box {
    border: 1px dashed;
    padding: 0.5rem 1rem 1rem 1rem;
    margin: 0 0 1.75rem 0;
    margin-bottom: 28px;
    border-color: var(--color-divider);
    min-height: 9rem;
}
.card-lines .source-box {
    border-left: 1px dashed;
    padding: 0.5rem 1rem;
    margin: .5rem;
    width: 10rem;
    border-color: var(--color-divider);
}
.card-scale .source-box {
    border: 1px dashed;
    padding: 0.5rem 1rem;
    margin: 1rem;
    border-color: var(--color-divider);
}
.item-view-panel .source-box {
    border: 1px dashed;
    padding: 0.5rem 1rem;
    border-color: var(--color-divider);
}
.source-box dl {
    padding: 0.125rem 0;
    margin: 0;
}
.source-box dt {
    color: rgba(0,0,0,.5);
    font-size: .75rem;
    line-height: 1rem;
}
.source-box dd {
    color: rgba(0,0,0,.8);
    font-size: .875rem;
    line-height: 1.125rem;
    margin: 0;
    padding: 0;
}
.card-scale .source-box dl {
    padding: 0.125rem 2rem 0.125rem 0.5rem;
    margin: .125rem;
    vertical-align: top;
    display: inline-block;
}
.source-box h5 {
    margin-top: .125rem;
    margin-bottom: .25rem;
}

.item-text {
    grid-column-start: span 2;
    padding: 1rem .5rem;
    /* line-height: 1.4rem; */
    /* paddingTop: 1rem',
    paddingBottom: p.fullscreen ? 0 : '2rem' */
}

.item-text p,ol,ul {
    font-family: "MuseoSansLight";
    font-size: 1.1rem;
    line-height: 1.5rem;
}

.item-text strong {
    opacity: .6;
}

.item-text h1, h2, h3, h4, h5, h6 {
    margin-top: 1.25rem;
}
.cm-edit-content h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}

.item-text hr {
    height: 0;
	border: none;
	border-top: 1px solid;
	border-color: var(--color-divider)
}

.item-view-panel .item-text {
    padding-left: 0;
    padding-right: 0;
}

.bottom-panel {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2
}
.top-panel {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2;
    width: 100%;
    /* height: 45px; */
}

.item-view-panel {
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: .5rem;
    position: 'relative'
}

.item-view {
    grid-template-columns: auto;
    display: grid;
    gap: 2rem;
    grid-gap: 2rem;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    position: relative
}

.item-view .flags {
    border-radius: 0.25rem;
    position: relative;
    margin: 0.5rem 0;
    top: unset;
}

@media (max-width: 760px) {
    .item-view-panel {
        /* padding: 0; */
        padding-top: 6px;
        padding-bottom: 12px;
        /* position: 'relative' */
    } 
    .item-view {
        margin-top: 0;
    }
    .item-view .chips {
        top: 12px;
        border: 1px solid;
    }
}
@media (min-width: 1024px) {
    .item-view {
        grid-template-columns: 1fr 1fr;
    }
    .item-view .title {
        font-family: "MuseoSansLight";
        font-size: 2.5rem;
        line-height: 3rem;
        letter-spacing: -.04em;        
    }
    .item-view .subtitle {
        font-family: "MuseoSansLight";
        font-size: 1.25rem;
        line-height: 1.5rem;
        margin-top: 0.25rem;
        }
    .item-view .label-box {
        margin-top: 1rem;
        font-size: 1rem;
        line-height: 1.75rem;
        color: rgba(0,0,0,.8);
    }
    .item-view .grid dt {
        color: rgba(0,0,0,.5);
        font-size: 0.8rem;
        line-height: 1.25rem;
    }
    .item-view .grid dd {
        color: rgba(0,0,0,.6);
        font-size: 1rem;
        line-height: 1.25rem;
    } 
    
}
.nosource {
    width: 100%;
    height: calc(100% - 2rem);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-action);
    font-size: .8rem;
    flex-wrap: wrap;
}