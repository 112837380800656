
.list-own h3 {
    margin-top: .5rem;
    color: rgba(0, 0, 0, 1);
}

.list-others h3 {
    margin-top: .25rem;
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 1);
}